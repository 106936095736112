@import "../../scss/mixin.scss";


.analysis{
  width: 100vw;
  overflow: hidden;
  @include height(2986+100);
  position: relative;
  @include mobile {
    // display: none;
  };

  .selectedPush{
    cursor: pointer;
    display: none;
    opacity: 0; 
    @include mobile {
      display: none;
    };
    

    transition: all 0.3s;
    position: fixed;
    @include bottom(50);
    left: 50%;
    transform: translate3d(-50%, 300%, 0);
    @include size(260,50);
    z-index: 10000;

    background-color: #002266;
    color: white;
    @include borderRadius(15);
    @include property(line-height,21);
    @include paddingTop(12);
    @include paddingBottom(13);

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat-Regular';
    @include fontSize(17);
  }
  .Warm{
    @include height(1452);
    width: 100%;
    background-color: #FFFFFA;
    @include mobile {
      height: auto;
    };
    .Warm-body{
      width: 100%;
      height: 100%;
      position: relative;
      .orange-blot{
        z-index: 0;
        position: absolute;
        @include top(200);
        @include size(1690,960);
        overflow: hidden;
        opacity: 0.35;
        @include mobile {
          display: none;
          top: mb(173);
          width: mb(738);
          height: mb(716);
        };
        img{
          width: auto;
          height: auto;
          transform: translateY(-20%)
        }
      }
      .About{
        position: relative;
        z-index: 1;
        @include paddingTop(101);
        @include paddingLeft(278);
        @include mobile {
          padding: 0;
        };
        .about-title{
          transform: translateY(mb(20)); // Под вопросом
          @include mobile {
            position: relative;
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
            margin-top: mb(45);
          };
        }
        .about-info{
          display: flex;
          align-items: center;
          @include marginTop(42);
          @include mobile {
            position: relative;
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
          };
          .info-text{
            @include paddingTop(45);
            @include mobile {
              padding-top: mb(30);
            };
            p{
              @include width(682);
              @include marginBottom(71);
              @include mobile {
                margin-bottom: 0px;
                width: mb(363);
              };
            }
            p:nth-child(1){
              @include mobile {
                margin-bottom: mb(211);
              };
            }
          }
          .info-img{
            @include marginLeft(62);
            @include size(758,345);
            @include borderRadius(20);
            position: relative;
            overflow: hidden !important;
            @include mobile {
              margin: 0;
              position: absolute;
              top: mb(240); left: mb(0);
              width: mb(375);
              height: mb(132);
              border-radius: mb(20)
            };
            .info-img-blur{
              transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0; left: 0;
              backdrop-filter: blur(8px);
              @include property(border-top-right-radius, 20);
              @include property(border-bottom-right-radius, 20);
              @include mobile {
                display: none;
              };
            }
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .Catalog{
        @include paddingLeft(278);
        @include marginTop(150);
        font-family: "Montserrat-Regular";

        width: 100vw;
        position: relative;
        @include paddingTop(100);
        @include paddingBottom(100);
        @include paddingLeft(210);
        @include mobile {
          height: auto;
          padding-left: 0;
          padding-top: mb(20);
          padding-bottom: 0;
        };
        .swicth {
          position: absolute;
          @include size(742,46);
          @include borderRadius(12);
          border: 1px solid #B1B1B1;
          @include top(103);
          @include left(962);
          background-color: #FFFFFA;
          @include mobile {
            position: static;
            top: 0; left: 0;
            border-radius: mb(10);
            width: mb(389); height: mb(33);
            margin-left: mb(13);
            margin-top: mb(30);
            display: none;
            
          };
          .wrap{
            width: 100%;
            height: 100%;
            position: relative;
            .figure {
              // display: none;
              transition: all 0.3s;
              position: absolute;
              top: 0;
              left: 0;
              @include size(294,45);
              @include borderRadius(12);
              background-color: #002266;
              z-index: 0;
              @include mobile {
                width: mb(284*389/542);
                height: mb(31)
              };
            }
            .switch-buttons {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              z-index: 2;
              .switch-button {
                cursor: pointer;
                transition: all 0.2s;
                user-select: none;
                &:nth-child(1){
                  @include paddingLeft(60);
                  @include paddingTop(9);
                  color: #FFFFFA;
                  @include mobile {
                    padding-top: mb(5);
                    padding-left: mb(30)
                  };
                }
                &:nth-child(2){
                  @include paddingLeft(87);
                  @include paddingTop(9);
                  @include mobile {
                    padding-top: mb(5);
                    padding-left: mb(63)
                  };
                }
                &:nth-child(3){
                  @include paddingLeft(87);
                  @include paddingTop(9);
                  @include mobile {
                    padding-top: mb(5);
                    padding-left: mb(63)
                  };
                }
                font-family: 'Montserrat-Regular';
                @include fontSize(18);
                @include width(542/2);
                height: 100%;
                justify-content: center;
                @include mobile {
                  width: mb(389/2);
                  font-size: mb(15)
                };
              }
            }
          }
        }
        .title {
          @include paddingLeft(68);
          @include width(811);
          @include mobile {
            padding-left: mb(26);
          };
        }
        .part{
          @include marginTop(40);
          @include paddingLeft(45);
          @include mobile {
            padding-left: 15px;
          };
          .subtitle{
            @include paddingLeft(23);
            font-family: 'Montserrat-Light';
            @include fontSize(18);
            @include paddingBottom(5);
            @include mobile {
              font-size: 18px;
              padding-left: 15px;
            };
          }
          .analys{
            @include size(1396, 50);
            @include borderRadius(15);
            border: 1px solid #CFCFCF;
            @include marginTop(20);
            display: flex;
            align-items: center;
            @include mobile {
              width: 95%;
              height: auto;
              height: 100px
            };
            .icon{
              cursor: pointer;
              flex-shrink: 0;
              @include size(20, 20);
              @include marginLeft(16);
              @include mobile {
                display: none;
              };
            }
            .name{
              font-family: 'Montserrat-Regular';
              @include fontSize(20);
              @include marginLeft(80);
              @include mobile {
                font-size: 18px;
                margin-left: 15px;
              };
            }
            .button{
              cursor: pointer;
              @include size(49,48);
              border-left: 1px solid #CFCFCF;
              @include borderRadius(15);
              margin-left: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.3s;
              @include mobile {
                display: none;
              };
            }
            .button-add{
              background-color: rgba(255, 255, 255, 0.55);
              &:hover{
                background-color: rgba(255, 255, 255, 1);
              }
              .plus-icon{
                @include size(15,15);
                flex-shrink: 0;
              }
            }
            .button-close{
              background-color: rgba(255, 255, 255, 0.84);
              // &:hover{
              //   background-color: rgba(255, 94, 138, 0.15);;
              // }
              .close-icon{
                @include size(15,15);
                flex-shrink: 0;
              }
            }
          }
          .base{
            background-color: #FFFDDC
          }
          .full{
            background-color: #EAEAFC
          }
          .more{
            background-color: #D9F7DE
          }
        }
      }
      .Form{
        @include paddingLeft(278);
        @include marginTop(135);
        @include mobile {
          display: none;
        };
        .title{
          @include paddingLeft(68);
        }
        .content{
          @include marginTop(46);
          display: flex;
          .selected{
            @include width(600);
            position: relative;
            .inner{
              @include size(600,450);
              border-left: 1px solid #9C9C9C;
              @include paddingLeft(25);
              .subtitle{
                font-family: 'Montserrat-Light';
                @include fontSize(18);
                @include marginBottom(20);
              }

              .selected-type{
                font-family: 'Montserrat-Regular';
                @include fontSize(20);
                display: inline-block;
                vertical-align: top;
                color: white;
                @include paddingTop(13);
                @include paddingBottom(13);
                @include paddingLeft(70);
                @include paddingRight(70);
                background-color: #002266;
                @include borderRadius(15);
                border: 1px solid #707070;
                width: auto;
                @include marginBottom(37);
              }

              .selected-list{
                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 5px;
                  border-radius: 10px;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(139, 161, 200, 0.7) !important;
                    border-radius: 10px;
                    border: 0px solid white;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #5e7398 !important;
                }
                @include size(575,261);
                .analys{
                  @include size(525, 50);
                  @include borderRadius(15);
                  border: 1px solid #CFCFCF;
                  @include marginBottom(20);
                  display: flex;
                  align-items: center;
                  .icon{
                    cursor: pointer;
                    flex-shrink: 0;
                    @include size(20, 20);
                    @include marginLeft(16);
                  }
                  .name{
                    font-family: 'Montserrat-Regular';
                    @include fontSize(20);
                    @include marginLeft(60);
                    @include width(313);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    user-select: none;
                  }
                  .button-inner{
                    cursor: pointer;
                    @include size(49,48);
                    border-left: 1px solid #CFCFCF;
                    @include borderRadius(15);
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 255, 255, 0.84);
                    .close-icon{
                      @include size(15,15);
                      flex-shrink: 0;
                    }
                  }
                }
                .base{
                  background-color: #FFFDDC
                }
                .full{
                  background-color: #EAEAFC
                }
                .more{
                  background-color: #D9F7DE
                }
                .no-selected{
                  font-family: 'Montserrat-Regular';
                  @include fontSize(20);
                  @include marginTop(50);
                  width: 100%;
                  text-align: center;
                }
              }
            }
            .button{
              @include marginTop(50);
              cursor: pointer;
              font-family: 'Montserrat-Regular';
              @include fontSize(20);
              position: absolute;
              @include right(0);
            }
          }
          .form-data{
            @include marginLeft(228);
            .info{
              @include width(811);
              font-family: 'Montserrat-Italic';
              @include fontSize(18);
              color: #9C9C9C;
              @include lineHeight(22);
            }
            .line{
              @include width(517);
              height: 1px;
              background-color: #9C9C9C;
              @include marginTop(40);
              @include marginBottom(40);
            }
            .point{
              font-family: 'Montserrat-Regular';
              @include fontSize(20);
              @include marginBottom(15);
            }
            input{
              border: none;
              outline: none;
              background-color: #F8F9FA;
              @include marginBottom(34);
              @include width(550);
              font-family: 'Montserrat-Italic';
              @include fontSize(25);
            }

            .send-button {
              display: flex;
              user-select: none;
              cursor: pointer;
              font-family: 'Montserrat-Regular';
              @include fontSize(20);
    
              .loading {
                @include size(50, 4);
                border: 2px solid #002266;
                @include borderRadius(10);
                background-color: #002266;
                @include marginRight(12);
                @include marginTop(11);
              }
            }
          }
        }
      }

    }
  }
  .Cold{
    @include height(1586+68);
    @include mobile {
      height: mb(1974+147);
      padding-bottom: mb(147);
      display: none;
    };
    width: 100vw;
    background-color: #F8F9FA;
  }
}