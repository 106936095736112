@import "../../scss/mixin.scss";

.service{
  width: 100%;
  @include paddingLeft(278);
  @include paddingRight(134);
  @include paddingBottom(100);
  min-height: 100vh;
  @include paddingTop(80);
  background-color: #FFFFFA;
  @include mobile {
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  };
  h2{
    @include marginTop(52);
    @include mobile {
      margin-top: 15px;
    };
  }
  p{
    @include marginTop(30);
    @include lineHeight(32);
    @include mobile {
      margin-top: 10px;
    };
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Montserrat-Regular';
    @include marginTop(30);
  }
  table, th, td {
    border: 1px solid rgb(100, 100, 100);
  }
  th, td {
    padding: 10px;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
  }
  .request{
    @include marginTop(107);
    font-family: 'Montserrat-Regular';
    @include fontSize(20);
    transform: translateX(-5%);
    @include mobile {
        display: none
        
    };
    .request-info{
      @include width(1050);
      text-align: center;
      margin: auto;
    }
    .request-button{
      transition: all 0.5s;
      margin: auto;
      @include marginTop(33);
      @include size(612,61);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #002266;
      @include borderRadius(10);
      color: white;
      cursor: pointer;
      &:hover {
        background-color: rgb(36, 104, 146);
        @include mobile {
          background-color: #002266
        };
      }
    }
  }
}