@import "../../../scss/mixin.scss";

.footer {
  width: 100%;
  @include property(height, 100);
  background-color: #FFFFFA;
  display: flex;
  justify-content: space-between;
  @include paddingTop(37);
  @include paddingLeft(206);
  @include paddingRight(140);
  @include fontSize(17);
  font-family: Montserrat-Bold;

  @include mobile {
    width: 100%;
    height: 96px;
    background-color: #FFFFFA;
    padding-top: 37px;
    padding-left: 0;
    padding-right: 0;
    font-family: Montserrat-Bold;
    font-size: 10px;
    display: block !important;
    padding-right: 34px;
    padding-left: 24px;
    display: none !important;
  }

  ;

  .footer__column_left {
    display: flex;
    justify-content: space-between;
    @include property(width, 387);

    @include mobile {
      display: flex;
      justify-content: center;
      width: auto;
    }

    ;

    .footer__logo_img {
      transform: translate3d(25px,0,0);
      @include size(32, 28);

      @include mobile {
        width: 35px;
        height: 17px;
        image-rendering: -webkit-optimize-contrast;
      }

      ;
    }

    .footer__copyright {
      display: flex;
      @include marginTop(2);

      .company {
        @include marginLeft(10);
      }
    }
  }

  .footer__column_right {
    display: flex;
    justify-content: space-between;
    @include property(width, 595);

    @include mobile {
      display: flex;
      justify-content: center;
      // justify-content: space-between;
      width: auto;
      margin-top: 10px;
    }

    ;

    .footer__phone {
      margin-left: 15px;
    }
    .footer__email{
      margin-left: 15px;
    }
    .footer__form {
      cursor: pointer;

      // opacity: 0.4;
      @include mobile {
        margin-right: 10px;
        // display: none;
      }

      ;
    }
  }
}

.mobile-footer {
  display: none;

  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: mb(100);
    background-color: #FFFFFA;
  }

  ;

  .info {
    @include mobile {
      margin-right: mb(33);
    }
    .company {
      font-family: 'Montserrat-Bold';
      font-size: mb(13);
      margin-bottom: mb(15);
      span{
        margin-left: mb(5);
      }
    }
    .contacts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .phone {
        font-family: 'Montserrat-Medium';
        font-size: mb(10);
      }
      .email {
        font-family: 'Montserrat-Medium';
        font-size: mb(11);
        margin-left: 5px;
      }
    }
  }

  .button {
    @include mobile {
      cursor: pointer;
      // opacity: 0.6;
      width: mb(115);
      height: mb(51);
      border-radius: mb(8);
      background-color: #002266;
      display: flex;
      align-items: center;
      color: white;
      font-family: 'Montserrat-Regular';
      font-size: mb(13);
      justify-content: center;
    };
  }
}






@media(max-width: 768px) {
  .footer {
    width: 100%;
    height: 96px;
    background-color: #FFFFFA;
    padding-top: 37px;
    padding-left: 0;
    padding-right: 0;
    font-family: Montserrat-Bold;
    font-size: 10px;
    display: block;
    padding-right: 34px;
    padding-left: 24px;
  }


  .footer__column_left {
    display: flex;
    justify-content: center;
    width: auto;
  }

  .footer__logo {}

  .footer__logo_img {
    /* transform: translatez(0);
        transform: translatex(0);
        transform: translatey(0); */
    width: 35px;
    height: 17px;
    image-rendering: -webkit-optimize-contrast;
  }

  .footer__copyright {
    margin-left: 8px;
    margin-top: 1px;
  }

  .footer__column_right {
    display: flex;
    justify-content: space-between;
    width: auto;
    margin-top: 10px;
  }

  .footer__form {
    cursor: pointer;
  }
}