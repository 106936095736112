@import "../../../scss/mixin.scss";
@import "../../../scss/fonts.scss";


.header {
  // opacity: 0.4;
  transition: all 0.15s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  @include property(height, 100);
  background-color: #FFFFFA;
  z-index: 200;
  // box-shadow: 0px 0px 24px #00000029;

  @include mobile {
    height: 52px;
    display: none;
  }


  .header-content {
    position: relative;
    display: flex;
    margin: auto;

    // max-width: 1640px;
    @include mobile {
      width: 100%;
      margin: 0;
      position: relative;
    }

    ;
  }

  .header-logo-block{
    display: flex;
    align-items: center;
    @include marginTop(13);
    z-index: 2;

  .header-logo {
    @include size(219, 74);
    @include marginLeft(40);
    @include paddingTop(7);
    z-index: 0;

    @include mobile {
      width: auto;
      margin: auto;
    }

    ;

    img {
      width: 100%;
      height: auto;

      @include mobile {
        width: 119px;
        height: 40px;
        transform: translatez(0);
        image-rendering: -webkit-optimize-contrast;
        background-color: white;
      }

    }
  }
  .quick{
    display: flex;
    align-items: center;
    @include marginLeft(35);
    .phone{
      font-family: 'Montserrat-Regular';
      @include fontSize(17);
    }
    .button{
      font-family: 'Montserrat-Regular';
      @include fontSize(17);
      @include marginLeft(35);
      cursor: pointer;
      @include height(40);
      display: flex;
      align-items: center;
      user-select: none;
      &:hover{
        transition: all 0.5s;
        color: rgb(238, 155, 0);
      }
    }
  }

  }

  .header-links-wrap{
    position: absolute;
    @include right(105);
    @include top(30);
    @include size(1400,50);
    // overflow-x: hidden;
    // overflow-y: visible;
    z-index: 1;

  .header-links {
    transition: all 0.65s cubic-bezier(.25,.1,.25,1);
    display: flex;
    transform: translateX(adpt(595));

  
    .header-link {
      flex-shrink: 0;
      transition: all 0.3s;
      font-family: SourceSerif4-Semibold;
      @include fontSize(25);
      color: black;
      @include marginRight(47);
      position: relative;
      
      
      
      &:nth-child(n+5):nth-child(-n+7){
        opacity: 0
      }
      &:hover{
        &::after{
          opacity: 1;
        }
        
      }

      &:nth-child(1):hover{
        &::after{
          opacity: 0;
        }
      }

      &::after{
        opacity: 0;
        transition: all 0.3s;
        @include borderRadius(5);
        position: absolute;
        content: '';
        @include bottom(-2);
        left: 50%;
        transform: translateX(-50%);
        width: 37%;
        @include height(2);
        // @include size(35,3);
        background-color: orange;
      }

      &-active {
        @include marginRight(120);
        position: relative;
        cursor: default;
        .tooltip{
          transition: all 0.3s;
          font-family: Montserrat-Regular;
          @include fontSize(14);
          position: absolute;
          @include top(9);
          @include left(-150);
          opacity: 0;
        }
        &:hover{
          .tooltip{
            opacity: 1;
          }
        }

        &::before {
          content: '';
          position: absolute;
          @include top(-8);
          @include right(-60);
          // @include width(2);
          width: 2px;
          @include height(49);
          background-color: #707070; 
        }

        
      }
      &-line{
        position: relative;
        &::after{
          opacity: 1;
          transition: all 0.3s;
          @include borderRadius(5);
          position: absolute;
          content: '';
          @include bottom(-2);
          left: 50%;
          transform: translateX(-50%);
          width: 37%;
          @include height(2);
          // @include size(35,3);
          background-color: orange;
          // opacity: 0.8;
        }
      }

      @include mobile {
        display: none;
      }
    }

    .header-link-menu{
      flex-shrink: 0;
      display: flex;
      align-items: center;
      position: relative;
      .header-menu-wrap{
        position: absolute;
        @include top(38);
        left: 50%;
        transform: translateX(-50%);
        .header-menu{
          display: none;
          flex-shrink: 0;
          @include marginTop(50);
          @include borderRadius(15);
          @include paddingTop(22);
          @include paddingBottom(22);
          @include paddingLeft(32);
          @include paddingRight(32);
          background-color: #FFFFFA;
          box-shadow: 0px 0px 12px #00000029;
          hr{
            height: 1px;
            @include width(120);
            background-color: rgb(238, 155, 0);
            border: 0;
            margin-left: 0;
          }
          .links{
            @include property(min-width,165);
            .link-wrap{
              @include marginTop(15);
              width: auto;
              &:nth-child(1){
                margin: 0;
              }
              .link{
                display: inline-block;
                font-family: 'Montserrat-Regular';
                @include fontSize(18);
                position: relative;
                width: auto;
                transition: all 0.5s;
                &::before{
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 80%;
                  height: 1px;
                  background-color:rgba(238, 155, 0, 0.678);
                  opacity: 0;
                  transition: all 0.5s;
                }
                &:hover{
                  color: rgb(238, 155, 0);
                  // &::before{
                  //   opacity: 1;
                  // }
                }
              }
            } 
          }
          .links-large{
            @include property(min-width,195);
          }
          .links-larger{
            @include property(min-width,285);

          }
        }
      }
      &::after{
        transform: translateX(-65%);
      }
      .icon{
        @include width(15);
        @include marginLeft(9);
        @include marginTop(-2);
        svg{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

  .OC-wrap{
    position: absolute;
    @include right(0);
    @include top(0);
    @include paddingRight(33);
    @include paddingTop(30);
    @include size(70,100);
    z-index: 3;
    background-color: #FFFFFA;
    cursor: pointer;
    .openClose-wrap {
      margin: auto;
      @include marginTop(10);
      @include size(35, 16);
      overflow: visible;
      @include mobile {
        display: none;
      };
      &:hover{
        .openClose{
          &:nth-child(2) {
            // @include size(35,2);
            @include width(35);
            height: 2px;
            // @include width(35);
            // width: 35px;
            // background-color: red;
          }
          &:nth-child(3) {
            // @include size(18,2);
            @include width(18);
            height: 2px;
            // @include width(18);
            // width: 18px;
            // background-color: red;
          }
        }
      }
      .openClose {
        display: block;
        transition: all 0.2s;
        // @include marginLeft(5);
        // @include size(35, 2);
        @include width(35);
        height: 2px;
        border: 5px;
        background-color: black;
        position: relative;
        @include marginBottom(6);
        // float: right;

        &:nth-child(2){
          // @include size(24,2);
          @include width(24);
          height: 2px;
          float: right;
        }
        &:nth-child(3){
          // @include size(12,2);
          @include width(12);
          height: 2px;
          float: right;

        }
    
        // &::after {
        //   transition: all 0.2s;
        //   content: '';
        //   position: absolute;
        //   @include top(16);
        //   @include right(0);
        //   @include size(12, 2);
        //   border: 5px;
        //   background-color: black;
        // }
      }
    }
  }
}

.mobile-header{
  display: none;
  @include mobile {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: mb(52);
    box-shadow: 0px 0px 24px #00000029;
    background-color: #FFFFFA;
    z-index: 100;
  };
  .page-link{
    @include mobile {
      height: 100%;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      font-family: 'Montserrat-Regular';
      font-size: mb(12);
      &:nth-child(1){
        margin-left: mb(20);
        margin-right: mb(25);
      }
      &:nth-child(2){
        margin-right: mb(26);
      }
      &:nth-child(3){
        margin-right: mb(24);
      }
    };
  }
  .menu-wrap{
    @include mobile {
      flex-shrink: 0;
      width: mb(54);
      height: 100%;
      margin-left: mb(13);
      display: flex;
      align-items: center
    };
  .menu{
    @include mobile {
      width: mb(17);
      height: mb(6);
      position: relative;
      margin: auto;
      transform: translateX();
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; height: 1px; 
        background-color: black
      }
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%; height: 1px; 
        background-color: black
      }
    };
  }    
  }
  
}




