@import "../../scss/mixin.scss";

.LabelClassTest{
  color: red !important;
}

.new-home {
  @include paddingTop(75);
  @include mobile {
    padding: 0;
    // display: none;
  };

  .main {
    background-color: #FFFFFA;
    position: relative;
    .header-line{
      transition: all 0.15s;
      position: absolute;
      @include top(-58);
      left: 50%;
      transform: translateX(-50%);
      @include width(1727);
      height: 1px;
      background-color: #707070;
      @include mobile {
        display: none;
      };
    }
    .changeBackground{
      background-image: url(assets/trans.jpg) !important;
      background-size: cover;
      background-position: 100% 100%;
    }
    .main-background {
      transition: background-image 1.2s;
      background-image: url(assets/turbo-main.jpg);
      background-size: cover;
      background-position: 100% 100%;
      margin: auto;
      @include size(1769, 1179);
      @include borderRadius(35);
      // @include paddingTop(433);
      // @include paddingLeft(72);
      overflow: hidden;
      position: relative;
      transform-style: preserve-3d;
      @include mobile {
        width: 100vw;
        height: mb(651);
        border-radius: 0;
        background-position: 70% 100%;
      };
      .shadow{
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 0;
      }

      .tezis {
        z-index: 2;
        position: absolute;
        @include top(433);
        @include left(72);
        @include width(1120);
        @include paddingTop(30);
        @include paddingBottom(25);
        @include borderRadius(17);
        @include paddingLeft(50);
        background-color: rgba($color: #FFFFFA, $alpha: 0.9);
        @include mobile {
          background-color: rgba($color: #FFFFFA, $alpha: 0.9);
          width: mb(393);
          height: mb(303);
          top: mb(25);
          left: 50%;
          transform: translateX(-50%);
          padding: 0;
          padding-top: mb(30);
          border-radius: mb(17)
        };
        .logo{
          display: none;
          @include mobile {
            display: block;
            width: mb(207);
            height: mb(55);
            margin: auto;
          };
        }

        // backdrop-filter: blur(14px) brightness(50%) opacity(35%);
        .tezis-info {
          font-family: 'Montserrat-Bold';
          @include fontSize(27);
          @include property(line-height, 40);
          @include mobile {
            font-size: mb(16);
            line-height: mb(25);
            margin-left: mb(17);
            margin-top: mb(35)
          };
        }

        .tezis-button {
          transition: all 0.5s;
          @include marginTop(20);
          @include borderRadius(10);
          @include width(240);
          @include paddingTop(12);
          @include paddingBottom(12);
          @include property(line-height, 22);
          text-align: center;
          background-color: #002266;
          font-family: 'Montserrat-Regular';
          @include fontSize(18);
          @include mobile {
            border-radius: 0;
            margin-top: mb(56);
            margin-left: mb(46);
            background-color: rgba(255, 255, 255, 0);
            border-top: 1px solid #B9B9B9;
            width: mb(300);
            color: black;
            font-size: mb(12);
            padding-top: mb(12);
            // background-color: red;
            // padding-bottom: mb(20)
            padding-bottom: mb(10)
          };
          &:hover{
            // background-color: #62909E;
            background-color: rgb(36, 104, 146);
            @include mobile {
              background-color: rgba(255, 255, 255, 0);
            };
          }

        }
        a {
          display: block;
          @include width(240);
          color: white;
        }
      }

      .line {
        position: absolute;
        // margin: auto;
        @include top(1145);
        @include right(81);
        background-color: #FFFFFA;
        @include size(1611, 10);
        @include borderRadius(3);
        @include mobile {
          top: mb(630);
          height: mb(5);
          width: mb(385);
          right: 50%;
          transform: translateX(50%)          
        };
      }
    }

  }

  .activity {
    @include marginTop(215);
    @include mobile {
      margin-top: mb(55);
    };

    .activity-info {
      @include marginLeft(281);
      @include mobile {
        margin-left: mb(26);
      };

      h1 {
        @include marginBottom(35);
        @include mobile {
          margin-bottom: mb(30);
        };
      }
      h3{
        @include marginBottom(35);
        @include mobile {
          margin-bottom: mb(30);
        };
      }

      .activity-text {
        p {
          @include property(line-height, 35);
          @include mobile {
            line-height: mb(30)
          };
          span{
            font-family: Montserrat-Medium;
            @include mobile {
              font-family: Montserrat-Regular;
            };
          }

          &:nth-child(1) {
            @include width(1344);
            // @include marginBottom(50);
            @include mobile {
              margin-bottom: mb(25);
              width: mb(363)
            };
          }

          &:nth-child(2) {
            @include width(850);
            @include mobile {
              width: mb(363)
            };
          }
        }
      }
    }

    .activity-cycles {
      .cycles-wrap {
        height: auto;
        // height: 270vh;
        position: relative;

        @include mobile {
          height: auto;
        };

        .cycles-body {
          position: sticky;
          top:0;
          overflow: hidden;
          // height: 100vh;
          @include mobile {
            top: 0; height: auto;
          };

          .circles {
            display: none;
            position: absolute;
            @include size(1160, 1160);
            opacity: 0.4;
            @include left(-673);
            @include top(266);
            @include borderRadius(1160/2);
            border: 1px solid #F29017;
            @include mobile {
              display: none;
            };
          }

          .circles-2 {
            display: none;
            @include size(1118, 1118);
            @include left(-673);
            @include top(266+42);
            @include borderRadius(1118/2);
            border: 1px solid #5E5E5C;
            @include mobile {
              display: none;
            };
          }


          .sticky-wrap {
            // width: 200vw;
            // display: flex;
            width: 100vw;
            display: block;
            @include mobile {
              width: 100vw;
              display: block;
            };

            .cycle-wrap {
              width: 100vw;
              // height: 100vh;
              @include marginBottom(155);
              @include mobile {
                width: auto; height: auto;
                margin-bottom: 0;
              };
              .cycle {
                margin: auto;
                @include marginTop(135);
                // margin-top: 172px;
                @include size(1401, 525);
                @include borderRadius(21);
                background-color: #FFFFFA;
                box-shadow: 0px 0px 24px #00000029;
                position: relative;
                overflow: hidden;
                @include mobile {
                  width: mb(363);
                  height: mb(576);
                  margin: auto;
                  margin-top: mb(55);
                  border-radius: mb(15);
                  display: flex;
                  flex-flow: column;
                };
                .circle{
                  position: absolute;
                  @include size(1160, 1160);
                  opacity: 0.4;
                  @include left(-935);
                  @include bottom(-726);
                  @include borderRadius(1160/2);
                  border: 1px solid #F29017;
                  @include mobile {
                    display: none;
                  };
                }
                .circle-2 {
                  // display: none;
                  @include size(1118, 1118);
                  @include left(-935);
                  @include bottom(-726);
                  @include borderRadius(1118/2);
                  border: 1px solid #5E5E5C;
                  @include mobile {
                    display: none;
                  };
                }


                .cycle-title {
                  font-family: 'Montserrat-Bold';
                  @include fontSize(28);
                  position: absolute;
                  @include top(55);
                  @include left(58);
                  @include mobile {
                    position: static;
                    font-size: mb(17);
                    margin-top: mb(20);
                    width: 100%;
                    text-align: center;
                    order: 1;
                  };
                }

                .cycle-operations {
                  position: absolute;
                  @include top(223);
                  @include left(86);
                  @include size(501, 239);
                  @include mobile {
                    position: static;
                    width: auto;
                    height: auto;
                    order: 3;
                    margin-left:mb(34);
                    margin-top:mb(23)
                  };

                  .operation {
                    font-family: 'Montserrat-Regular';
                    @include fontSize(20);
                    @include mobile {
                      font-size: mb(15);
                      position: relative ;
                      margin-bottom:mb(20) !important;
                      &::after{
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: mb(6);
                        height: mb(6);
                        background-color: black;
                        left: mb(-15);
                        border-radius: mb(3);
                      }
                    };
                  }
                }

                .cycle-info {
                  font-family: 'Montserrat-Regular';
                  @include fontSize(18);
                  @include lineHeight(35);
                  position: absolute;
                  @include width(653);
                  @include top(45);
                  @include left(689);
                  @include mobile {
                    position: static;
                    order: 2;
                    font-size: mb(15);
                    line-height: mb(30);
                    width: mb(334);
                    margin-left: mb(19);
                    margin-top: mb(27)
                  };
                }

                .cycle-img {
                  @include size(652, 305);
                  position: absolute;
                  @include top(189);
                  @include left(689);
                  @include borderRadius(15);
                  @include mobile {
                    position: static;
                    order: 4;
                    width: mb(335);
                    height: mb(160);
                    margin: auto;
                  };

                }

                .QA {
                  background-image: url(./assets/QA.jpg);
                  background-size: cover;
                  background-position: 50% 50%;
                  image-rendering: -webkit-optimize-contrast;
                }

                .basic {
                  // background-image: url(./assets/basicNew.jpg);
                  // background-image: url(./assets/basic.jpg);
                  background-image: url(./assets/trans-1.jpg);
                  background-size: cover;
                  background-position: 50% 50%;
                  image-rendering: -webkit-optimize-contrast;
                }

                .full {
                  // background-image: url(./assets/basic.jpg);
                  // background-image: url(../../media/img/murm-compressed.png);
                  background-image: url(./assets/2.jpg);
                  background-size: cover;
                  background-position: 50% 50%;
                  image-rendering: -webkit-optimize-contrast;
                }
              }

              .basic {
                .cycle-operations {
                  .operation {
                    @include mobile {
                      margin-bottom:mb(25) !important;
                    };
                    &:nth-child(2) {
                      @include marginLeft(76);
                      @include marginTop(84);
                      @include mobile {
                        width: mb(253);
                        margin: 0;
                      };
                    }

                    &:nth-child(3) {
                      @include marginLeft(155);
                      @include marginTop(83);
                      @include mobile {
                        margin: 0;
                      };
                    }
                  }
                }
              }

              .full {
                .cycle-operations {
                  .operation {
                    &:nth-child(2) {
                      @include marginLeft(47);
                      @include marginTop(48);
                      @include mobile {
                        margin: 0;
                      };
                    }

                    &:nth-child(3) {
                      @include marginLeft(92);
                      @include marginTop(49);
                      @include mobile {
                        margin: 0;
                      };
                    }

                    &:nth-child(4) {
                      @include marginLeft(154);
                      @include marginTop(48);
                      @include mobile {
                        margin: 0;
                      };
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .qualities {
    background-color: #F8F9FA;
    width: 100vw;
    position: relative;
    @include paddingTop(100);
    @include paddingBottom(100);
    @include paddingLeft(210);
    @include mobile {
      height: auto;
      padding-left: 0;
      padding-top: mb(120);
    };
    .qualities-swicth {
      position: absolute;
      @include size(542,46);
      @include borderRadius(12);
      border: 1px solid #B1B1B1;
      @include top(101);
      @include left(1262);
      background-color: #FFFFFA;
      @include mobile {
        position: static;
        top: 0; left: 0;
        border-radius: mb(10);
        width: mb(389); height: mb(33);
        margin-left: mb(13);
        margin-top: mb(30)
      };
      .wrap{
        width: 100%;
        height: 100%;
        position: relative;
        .figure {
          // display: none;
          transition: all 0.3s;
          position: absolute;
          top: 0;
          left: 0;
          @include size(284,45);
          @include borderRadius(12);
          background-color: #002266;
          z-index: 0;
          @include mobile {
            width: mb(284*389/542);
            height: mb(31)
          };
        }
        .switch-buttons {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          z-index: 2;
          .switch-button {
            cursor: pointer;
            transition: all 0.2s;
            user-select: none;
            &:nth-child(1){
              @include paddingLeft(60);
              @include paddingTop(9);
              color: #FFFFFA;
              @include mobile {
                padding-top: mb(5);
                padding-left: mb(30)
              };
            }
            &:nth-child(2){
              @include paddingLeft(87);
              @include paddingTop(9);
              @include mobile {
                padding-top: mb(5);
                padding-left: mb(63)
              };
            }
            font-family: 'Montserrat-Regular';
            @include fontSize(18);
            @include width(542/2);
            height: 100%;
            justify-content: center;
            @include mobile {
              width: mb(389/2);
              font-size: mb(15)
            };
          }
        }
      }
    }
    .qualities-title {
      @include paddingLeft(68);
      @include mobile {
        padding-left: mb(26);
      };
    }
    .qualities-view {
      @include marginTop(90);
      display: flex;
      @include mobile {
        margin-top:mb(64);
        display: block;
      };
      .info {
        @include width(750);
        @include mobile {
          width: auto;
        };
        .view-title {
          @include mobile {
            padding-left: mb(26)
          };
        }
        .view-info {
          @include marginTop(30);
          @include width(658);
          @include mobile {
            padding-left: mb(26);
            margin-top: mb(30);
            width: mb(378);
          };
          p{
            @include lineHeight(35);
            @include mobile {
              line-height: mb(35);
            };
          }
        }
        .table-wrap{
          @include mobile {
            background-color: #FFFFFA;
            width: mb(393);
            margin-top: mb(35);
            padding-top: mb(7);
            margin-left: mb(11);
          };
          .open-table-button{
            display: none;
            @include mobile {
              padding-bottom: mb(7);
              cursor: pointer;
              display: block;
              font-family: 'Montserrat-Regular';
              font-size: mb(15); 
              width: 100%;
              text-align: center;
            };
          }
          .close-table-button{
            display: none;
            @include mobile {
              display: none;
              cursor: pointer;
              font-family: 'Montserrat-Regular';
              font-size: mb(15);  
              width: 100%;
              text-align: center;
            };
          }
          .view-table {
            display: flex;
            @include marginTop(70);
            font-family: 'Montserrat-Regular';
            @include fontSize(20);
            @include mobile {
              display: none;
              width: mb(370);
              margin-top: mb(7); 
              margin-left: mb(12); 
              font-size: mb(13);       
            };
            .column{
              &:nth-child(1){
                @include width(437);
                @include mobile {
                  width: mb(195);
                };
              }
              &:nth-child(2){
                @include width(280);
                @include marginLeft(33);
                @include mobile {
                  margin-left: mb(16);
                  width: mb(158);
                };
              }
              .column-head{
                @include paddingTop(12);
                @include paddingBottom(10);
                @include marginBottom(28);
                @include mobile {
                  margin-bottom: mb(15);
                };
              }
              .point{
                @include marginBottom(22);
                @include mobile {
                  margin-bottom: mb(30);
                };
              }
            }
          }
          
          .table-0{
            border-top: 2px solid #002266;
            @include mobile {
              border-top: 1px solid #002266;
            };
            .column{
              &:nth-child(2){
                .points>.point:nth-child(1){
                  @include mobile {
                    margin-bottom: mb(50)
                  };
                  
                }
                .points>.point:nth-child(4){
                  @include mobile {
                     margin-bottom: mb(50)
                  };
                }
              }
              .column-head{
                border-bottom: 2px solid #002266;
                @include mobile {
                  border-bottom: 1px solid #002266;
                };
              }
              .point{
                span{
                  color: #002F8E;
                }
              }
            }
          }
          .table-1{
            border-top: 2px solid #F3A447;
            @include mobile {
              border-top: 1px solid #F3A447;
            };
            .column{
              &:nth-child(2){
                .points>.point:nth-child(1){
                  @include mobile {
                     margin-bottom: mb(50)
                  };
                 
                }
                
              }
              .column-head{
                border-bottom: 2px solid #F3A447;
                @include mobile {
                  border-bottom: 1px solid #F3A447;
                };
              }
              .point{
                span{
                  color: #F7931E;
                }
              }
            }
          }
        }
      }
      .img {
        @include size(712,493);
        @include marginLeft(77);
        @include marginTop(59);
        @include borderRadius(35);
        @include mobile {
          width: mb(370);
          height: mb(256);
          margin-left: mb(23);
          margin-top: mb(40);
          border-radius: mb(15)
        };
        img{
          width: auto;
          height: 100%;
        }
      }
    }
  }
  .calculator{
    width: 100vw;
    background-color: #FFFFFA;
    @include paddingLeft(278);
    @include mobile {
      padding: 0;
    };
    .line{
      @include size(1611,10);
      margin: auto;
      @include marginLeft(-123);
      @include marginTop(23);
      @include borderRadius(3);
      background-color: #F8F9FA;
      @include mobile {
        display: none;
      };
    }
    .calculator-title{
      @include marginTop(183);
      @include mobile {
        margin-top: mb(120);
        padding-left: mb(26);
      };
    }
    .calculator-info{
      @include marginTop(35);
      @include width(1010);
      span{
        display: none;
        @include mobile {
          display: inline;
        };
        span{
          font-family: 'Montserrat-Bold';
        }
      }
      @include mobile {
        margin-top: mb(30);
        padding-left: mb(26);
        width: mb(395);
      };
      p{
        @include lineHeight(35);
      }
    }
    .wrap{
      display: flex;
      @include marginTop(114);
      @include mobile {
        display: block;
      };
      .calculate-mobile{
        display: none;
        @include mobile {
          font-family: 'Montserrat-Regular';
          font-size: mb(15);
          margin-top: mb(35);
          width: mb(368);
          height: mb(45);
          background-color: #002266;
          border-radius: mb(10);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: mb(23);
          cursor: pointer;
        };
      }
      .calculating{
        @include size(841,660);
        @include mobile {
          display: none;
        };
        .radio-groups{
          display: flex;
          .radio-group{
            @include marginRight(93);
            .radio-title{
              @include width(350);
              font-family: 'Montserrat-Bold';
              @include fontSize(25);
            }
            .wrap{
              display: block;
              @include marginTop(56);
              .radio{
                @include marginBottom(24);
                display: flex;
                align-items: center;
                font-family: 'Montserrat-Regular';
                @include fontSize(22);
                .radio-button{
                  cursor: pointer;
                  @include size(24,24);
                  @include borderRadius(12);
                  border: 2px solid #B1B1B1;
                }
                .radio-name{
                  cursor: pointer;
                  @include paddingLeft(15);
                }
                .active{
                  position: relative;
                  &::after{
                    content: '';
                    @include size(14,14);
                    @include borderRadius(7);
                    position: absolute;
                    // @include top(2.5);
                    // @include left(2.5);
                    top:50%; left: 50%;
                    transform: translate3d(-50%,-50%,0);
                    background-color: #6C6CD5;
                  }
                }
              }
            }
            .flex{
              display: flex;
              .radio{
                &:nth-child(2){
                  @include marginLeft(45);
                }
              }
            }
          }
        }
        .slider-value{
          @include marginTop(45);
          @include marginBottom(98);
          .slider-title{
            font-family: 'Montserrat-Bold';
            @include fontSize(25);
          }
          .slider-body{
            @include marginTop(50);
            @include size(836,67);
          }
        }
      }
      .result{
        @include marginLeft(154);
        @include paddingTop(42);
        @include mobile {
          padding: 0; margin: 0; 
          margin-top: mb(86);
          text-align: center
        };
        .result-title{
          font-family: 'Montserrat-Bold';
          @include fontSize(25);
          @include mobile {
            font-size: mb(18)
          };
        }
        .result-value{
          @include marginTop(30);
          font-family: 'Montserrat-Light';
          @include fontSize(100);
          @include mobile {
            font-size: mb(70)
          };
          span{
            font-family: 'Montserrat-Regular';
            @include fontSize(20);
            @include mobile {
              font-size: mb(15);
            };
          }
        }
        .result-button{
          transition: all 0.5s;
          @include size(491,71);
          background-color: #002266;
          @include borderRadius(15);
          color: white;
          @include marginTop(90);
          font-family: 'Montserrat-Regular';
          @include fontSize(19);
          text-align: center;
          @include paddingTop(22);
          cursor: pointer;
          @include mobile {
            display: none;
          };
          &:hover{
            // background-color: #62909E;
            background-color: rgb(36, 104, 146);
            @include mobile {
              background-color: #002266
            };
          }
        }
      }
    }
  }
  .plants{
    background-color: #FFFFFA;
    @include marginTop(208);
    @include paddingLeft(278);
    @include paddingBottom(244);
    @include mobile {
      margin-top:mb(140);
      padding-left: 0;
      padding-bottom: mb(190);
    };
    .wrap{
      display: flex;
      @include mobile {
        display: block;
      };
    .info{
      .info-title{
        @include mobile {
          padding-left: mb(26);
        };
      }
      .info-text1{
        @include marginTop(30);
        @include width(647);
        @include mobile {
          margin-top: mb(30);
          padding-left: mb(26);
          width: mb(395);
        };
        p{
          @include lineHeight(35);
          @include mobile {
            line-height: mb(35);
          };
        }
      }
      .info-text2{
        @include marginTop(40);
        @include mobile {
          margin-top: mb(40);
          padding-left: mb(26);
        };
        p{
          @include lineHeight(35);
          @include mobile {
            line-height: mb(35);
          };
        }
      }
      .points{
        @include marginTop(65);
        @include mobile {
          margin-top: mb(20);
          padding-left: mb(26);
        };
        .point{
          align-items: center;
          display: flex;
          font-family: 'Montserrat-Medium';
          @include fontSize(23);
          @include marginBottom(40);
          @include mobile {
            font-size: mb(17);
            margin-bottom: mb(20)
          };
          .dot{
            @include size(8,8);
            @include borderRadius(4);
            background-color: black;
            @include marginRight(25);
            @include mobile {
              margin-right: mb(20)
            };
          }
        }
      }
    }
    .img{ 
      @include marginTop(86);
      @include marginLeft(62);
      @include size(680,472);
      @include borderRadius(20);
      background-image: url(./assets/manufactory.jpg);
      background-size: cover;
      background-position: 50% 50%;
      image-rendering: -webkit-optimize-contrast;
      position: relative;
      @include mobile {
        margin-top: mb(65);
        margin-left: mb(16);
        width: mb(385); height: mb(178);
        border-radius: mb(10);
        background-position: 50% 0%;
      };
      .button{
        transition: all 0.3s;
        position: absolute;
        top: 0;
        right: 0;
        @include size(80,472);
        @include borderRadius(15);
        @include right(-11-80);
        // background-color: #5F7BAC;
        background-color: #002266;
        // #f3901e
        cursor: pointer;
        @include paddingTop(222);
        @include mobile {
          position: static;
          width: mb(385);
          height: mb(41);
          padding-top: mb(10);
          transform: translateY(mb(183));
          border-radius: mb(10)
        };
        &:hover{
          @include size(87,472);
          @include mobile {
            width: mb(385);
            height: mb(41);
          };
        }
        .icon{
          margin: auto;
          @include size(36,27);
          @include mobile {
            width: mb(26); height: mb(19);
          };
          svg{
            width: 100%;
            height: auto;
          }
        }
        
      }
    }
   
    }
  }
  .newMap{
    width: 100vw;
    @include height(543);
    overflow: hidden;
    @include mobile {
      display: none;
    };
    .newMap-body{
      // width: 100%;
      // height: 100%;
      width: 100vw;
      @include height(543);
      overflow: hidden;
    }
  }
}
.mobileAtten{
   display: none;
   @include mobile {
    font-family: 'Montserrat-Regular';
    @include fontSize(22);
    // display: block;
    width: 100vw;
    height: 100vh;
    text-align: center;
    margin-top: 35vh;
    @include paddingLeft(30);
    @include paddingRight(30);
   };
}