@import "../../scss/mixin.scss";

body {
  background-color: #FFFFFA;
  width: auto;
  height: auto;
}

.contacts__content {
  margin: auto;
  @include paddingTop(79);
  display: flex;
  @include height(848);
  position: relative;
  max-width: 2000px;
  @include mobile {
    margin: auto;
    margin-top: 0;
    padding-top: 0;
    display: block;
    height: auto;
  };

  .contacts__info {
    @include marginLeft(140);
    @include size(690, 735);
    position: relative;
    @include mobile {
      margin: auto;
      width: mb(369);
      height: auto;
      position: relative;
    };
    a {
      transition: all 0.2s;
      color: black;
      &:hover {
        color: #F27300;
        @include mobile {
          color: black;
        };
      }
    }

    .contacts__title {
      position: absolute;
      top: 0;
      left: 0;
      user-select: none;
      @include mobile {
        position: static;
        width: mb(379);
        margin: auto;
        margin-top: mb(30);
       };
    }

    .contacts__phone {
      cursor: pointer;
      position: absolute;
      @include top(134);
      left: 0;
      @include mobile {
        margin-top: mb(40);
        position: static;
      };
      h2{
        user-select: none;
        @include mobile {
          font-size: mb(17);
          font-family: SourceSerif4-Bold
        };
      }
      p{
        @include marginTop(25);
        -webkit-user-select: text !important; /* Safari */
        -moz-user-select: text !important; /* Firefox */
          -ms-user-select: text !important; /* Internet Explorer/Edge */
              user-select: text !important; /* Стандартный синтаксис */
        @include mobile {
          margin-top: mb(10);
        };
      }
    }

    .contacts__email {
      cursor: pointer;
      position: absolute;
      @include top(134);
      @include left(275);
      @include mobile {
        margin-top: mb(41);
        position: static;
      };
      h2{
        user-select: none;
        @include mobile {
          font-size: mb(17);
          font-family: SourceSerif4-Bold
        };
      }
      p{
        @include marginTop(25);
        -webkit-user-select: text !important; /* Safari */
        -moz-user-select: text !important; /* Firefox */
          -ms-user-select: text !important; /* Internet Explorer/Edge */
              user-select: text !important; /* Стандартный синтаксис */
        @include mobile {
          margin-top: mb(10);
        };
      }
    }

    .contacts__address {
      position: absolute;
      @include top(323);
      left: 0;
      @include width(225);
      @include mobile {
        width: mb(364);
        margin-top: mb(41);
        position: static;
      };
      h2{
        user-select: none;
        @include mobile {
          font-size: mb(17);
          font-family: SourceSerif4-Bold
        };
      }
      p{
        @include marginTop(25);
        @include mobile {
          margin-top: mb(10);
          line-height: mb(25);
        };
      }
    }

    .contacts__card {
      @include width(206);
      user-select: none;
      @include property(line-height, 25);
      position: absolute;
      @include top(323);
      @include left(275);
      display: flex;
      @include property(line-height, 30);
      align-items: center;
      @include mobile {
        width: auto;
        margin-top: mb(41);
        position: static;
        display: flex;
        font-size: mb(17);
        font-family: SourceSerif4-Bold !important;
      };
      h2{
        user-select: none;
        @include mobile {
          font-size: mb(17);
          font-family: SourceSerif4-Bold
        };
      }
      .contacts__file {
        flex-shrink: 0;
        @include marginTop(8);
        @include marginLeft(33);
        @include size(39, 44);
        @include mobile {
          margin-top: 0;
          margin-left: mb(33);
          width: mb(26);
          height: mb(30);
        };
      }
    }

    .test_info_test {
      position: absolute;
      @include top(519);
      @include left(0);
      @include fontSize(19);
      @include mobile {
        display: none;
      };
    }

    .contacts__button {
      user-select: none;
      transition: all 0.5s;
      cursor: pointer;
      position: absolute;
      @include top(589);
      @include left(0);
      @include size(495, 53);
      background-color: #002266;
      @include borderRadius(10);
      text-align: center;
      font-family: Montserrat-Regular;
      @include fontSize(19);
      color: white;
      @include paddingTop(12);
      @include mobile {
        position: static;
        margin: auto;
        margin-top: mb(72);
        width: mb(363);
        height: mb(42);
        font-size: mb(10);
        padding-top: mb(9);
        font-size: mb(15);
      };

      &:hover {
        background-color: rgb(36, 104, 146);
        @include mobile {
          background-color: #002266
        };
        // box-shadow: 0px 0px 14px #00000047;
        // @include mobile {
        //   box-shadow: 0px 0px 0px #00000000;
        // };
      }

      &:active {
        background-color: #002266b2;
        box-shadow: 0px 0px 0px #0000003a;
        @include mobile {
          background-color: #002266;
          box-shadow: 0px 0px 0px #00000000;
        };
      }
    }
  }

  .contacts__map {
    background-color: rgba(128, 128, 128, 0.152); 
    // background-image: url(../../media/img/logo_full.png);
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: 50% 50%;
    @include size(950, 735);
    @include mobile {
      margin: auto;
      margin-top: mb(54);
      width: mb(345) !important;
      height: mb(589);
    };
  }
}


.contacts__footer {
  width: 100%;
  @include height(110);
  background-color: #FFFFFA;
  @include paddingTop(37);
  font-family: Montserrat-Bold;
  @include fontSize(17);
  @include mobile {
    display: none;
    height: auto;
    padding: 0;
    font-size: mb(14);
    margin-top: mb(46);
    padding-bottom: mb(34);
  };

  .footer__container {
    display: flex;
    align-items: center;
    @include width(420);
    margin: auto;
    @include mobile {
      width: mb(330);
      margin: auto;
    };

    .footer__logo_img {
      @include width(65*0.8);
      height: auto;
      @include mobile {
        width: mb(49*0.8);
      };
    }

    .footer__copyright {
      @include marginLeft(16);
      span{
        @include marginLeft(8);
        @include mobile {
          margin-left: mb(8)
        };
      }
    }
  }
}



a {
  text-decoration: none;
  color: black;
}

