@import "../../scss/mixin.scss";


.NewService{
  .Warm{
    // @include height(2490);
    @include paddingBottom(100);
    width: 100%;
    background-color: #FFFFFA;
    @include mobile {
      // height: mb(1602);
      padding-bottom: mb(65)
    };
    .Warm-body{
      width: 100%;
      height: 100%;
      position: relative;
      .orange-blot{
        z-index: 0;
        position: absolute;
        @include top(200);
        @include size(1690,960);
        overflow: hidden;
        opacity: 0.35;
        @include mobile {
          display: none;
          top: mb(173);
          width: mb(738);
          height: mb(716);
        };
        img{
          width: auto;
          height: auto;
          transform: translateY(-20%)
        }
      }
      .blue-blot{
        z-index: 0;
        position: absolute;
        @include top(200);
        @include size(1690,960);
        overflow: hidden;
        opacity: 0.35;
        @include mobile {
          display: none;
          top: mb(173);
          width: mb(738);
          height: mb(716);
        };
        img{
          width: 100%;
          height: auto;
          transform: translateY(-20%)
        }
      }
      .About{
        position: relative;
        z-index: 1;
        @include paddingTop(101);
        @include paddingLeft(278);
        @include mobile {
          padding: 0;
        };
        .about-title{
          @include mobile {
            position: relative;
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
            margin-top: mb(45);
          };
        }
        .about-info{
          display: flex;
          align-items: center;
          @include marginTop(42);
          @include mobile {
            position: relative;
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
          };
          .info-text{
            @include paddingTop(45);
            @include mobile {
              padding-top: mb(30);
            };
            p{
              @include width(586);
              @include marginBottom(71);
              @include mobile {
                margin-bottom: 0px;
                width: mb(363);
              };
            }
            p:nth-child(1){
              @include mobile {
                margin-bottom: mb(211);
              };
            }
          }
          .info-img{
            @include marginLeft(98);
            @include size(758,345);
            @include borderRadius(20);
            position: relative;
            overflow: hidden !important;
            @include mobile {
              margin: 0;
              position: absolute;
              top: mb(160); left: mb(0);
              width: mb(375);
              height: mb(132);
              border-radius: mb(20)
            };
            .info-img-blur{
              transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0; left: 0;
              backdrop-filter: blur(8px);
              @include property(border-top-right-radius, 20);
              @include property(border-bottom-right-radius, 20);
              @include mobile {
                display: none;
              };
            }
            img{
              width: 100%;
              height: auto;
              transform: translateY(-15%);
            }
          }
        }
      }
      .List{
        position: relative;
        @include paddingTop(301);
        @include paddingLeft(278);
        @include paddingRight(278);
        @include mobile {
          padding-left: mb(10);
          padding-right: 0;
          padding-top: mb(50);
          
        };
        h2{
          @include mobile {
            padding-left: mb(20)
          };
        }
        .steps{
          // @include paddingLeft(700);
          margin: auto;
          @include paddingTop(80);
          @include mobile {
            padding-top: mb(40)
          };
          .step-blue{
            border: 1px solid rgba(0, 47, 255, 0.384);  
          }
          .step-orange{
            border: 1px solid rgba(255, 166, 0, 0.384);  
          }
          .step{
            // margin: auto;
            @include marginLeft(200);
            font-family: "Montserrat-Regular";
            @include fontSize(21);
            @include marginBottom(40);
            @include width(850);
            @include paddingTop(20);
            @include paddingBottom(18);
            // @include size(600,100);
            // border: 2px solid black;
            display: flex;
            align-items: center;
            justify-content: center;
            @include property(border-radius,15);
            box-shadow: 0px 0px 24px #00000029; 
            background-color: #FFFFFA;    
            display: flex;
            text-align: center;
            @include mobile {
              padding-top: mb(20);
              padding-bottom: mb(18);
              margin-left: mb(20);
              margin-bottom: mb(40);
              font-size: mb(14);
              width: mb(340);
              box-shadow: 0px 0px 12px #00000029; 
            };
          }
        }
      }
      .Final{
        position: relative;
        z-index: 1;
        @include paddingTop(121);
        @include paddingLeft(278);
        @include mobile {
          padding: 0;
          padding-top: mb(50);
          padding-left: mb(30)
        };
        h2{
          @include marginBottom(40);
          @include mobile {
            margin-bottom: mb(20);
          };
        }
        .info_ex{
          @include marginTop(15);
          @include width(1300);
          @include mobile {
            width: mb(350)
          };
        }
      }
     
      .request{
        @include marginTop(187);
        font-family: 'Montserrat-Regular';
        @include fontSize(20);
        // transform: translateX(-5%);
        @include mobile {
            display: none
            
        };
        .request-info{
          @include width(1050);
          text-align: center;
          margin: auto;
        }
        .request-button{
          transition: all 0.5s;
          margin: auto;
          @include marginTop(33);
          @include size(612,61);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #002266;
          @include borderRadius(10);
          color: white;
          cursor: pointer;
          &:hover {
            background-color: rgb(36, 104, 146);
            @include mobile {
              background-color: #002266
            };
          }
        }
      }
      

    }

  }
  .Cold{
    @include height(726);
    @include mobile {
      height: mb(1000);
      padding-bottom: mb(147);
    };
    width: 100%;
    background-color: #F8F9FA;
    .SameServices{
      position: relative;
      z-index: 1;
      @include paddingTop(100);
      @include paddingLeft(278);
      @include mobile {
        padding: 0;
        padding-left: mb(30);
        padding-top: mb(50)
      };

      h2{
        @include mobile {
          margin-bottom: mb(30)
        };
      }
     
      .services-list{
        display: flex;
        @include paddingTop(50);
        @include mobile {
          display: block;
          padding: 0;
        };
        .service-item{
          @include marginRight(40);
          transition: all 0.3s;
          @include mobile {
            margin: 0;
            display: inline-block;
            width: auto;
            height: auto;
            margin-bottom: mb(30)
          };
          &:hover{
            .service-name{
              color: orange
            }
          }
          .service-img{
            @include size(450,300);
            @include borderRadius(15);
            overflow: hidden;
            @include mobile {
              width: mb(310);
              height: mb(170)
            };
            img{
              transition: all 0.3s;
              &:hover{
                transform: scale(1.03);
                @include mobile {
                  transform: scale(1);
                };
              }
            }
          }
          .service-name{
            transition: all 0.3s;
            font-family: 'Montserrat-Regular';
            @include fontSize(21);
            @include marginTop(20);
            @include mobile {
              font-size: mb(14);
              margin-top: mmb(10)
            };
            
          }
        }
      }
    }
  }
}