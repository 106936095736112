@import "../../scss/mixin.scss";

.technology {
  background-color: #FFFFFA;
}

.technology__aboutTech {
  @include paddingTop(80);
  @include paddingLeft(278);
  @include paddingRight(134);
  @include paddingBottom(72);
  position: relative;
  @include mobile {
    padding: 0;
    padding-left: 15px;
    padding-top: 15px;
  };

  .aboutTech__text1 {
    @include marginTop(45);
    @include mobile {
      margin-top: 15px;
    };
  }

  .aboutTech__tezis {
    @include height(112);
    position: relative;
    @include marginTop(69);
    @include marginLeft(221);
    @include paddingTop(31);
    @include paddingLeft(35);
    @include mobile {
      padding: 0;
      margin: 0;
      margin-top: 25px;
      padding-left: 15px;
      height: auto;
    };

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 2px;
      max-height: 100%;
      border-radius: inherit;
      background: linear-gradient(to bottom, black 0%, #F27300 60%);
    }
  }

  .aboutTech__text2 {
    @include marginTop(71);
    @include mobile {
      margin-top: 25px;
    };
  }
}


.technology__system {
  background-color: #F8F9FA;
  @include paddingTop(98);
  @include mobile {
    display: none;
  };

  .techprocess__info{
    @include paddingLeft(278);
    .techprocess__title{
      font-family: "Montserrat-Bold";
      @include fontSize(25);
    }
    .techprocess__text{
      @include marginTop(39);
      @include width(1500);
    }
  }

  .chemistry__opener {
    @include width(1640);
    background-color: #FFFFFA;
    margin: auto;
    @include marginTop(87);

    .opener__visible {
      cursor: pointer;
      @include paddingTop(50);
      @include paddingLeft(138);
      border-top-width: 1px;
      border-top-style: solid;
      border-color: black;
      position: relative;
      @include height(132);

      .opener__button {
        position: absolute;
        @include top(52);
        @include right(48);
        @include size(27, 27);

        .opener__arrow {
          transform: rotate(90deg);
        }
      }
    }

    .opener__hidden {
      @include height(640);
      display: none;
      @include paddingLeft(132);

      .hidden__wrap {
        @include paddingTop(150);
        display: flex;
        font-family: Montserrat-Bold;
        @include fontSize(20);
        position: relative;

        .opener__columnNames {
          font-family: Montserrat-regular;
          @include fontSize(15);
          color: #888888;
          position: absolute;
          @include top(50);
          left: 0;
          display: flex;

          .pollution_delete {
            @include marginLeft(275);
          }

          .pollution {
            @include marginLeft(360);
          }
        }

        .opener__hidden_left {
          @include width(333);

          .opener__hidden_operation {
            @include marginBottom(100);
            @include marginRight(50);
          }

          .center {
            @include marginTop(120);
          }
        }

        .opener__hidden_right {
          @include height(366);
          border-left-style: solid;
          border-width: 3px;
          border-color: black;
          @include paddingLeft(147);

          .hidden__block {
            @include marginBottom(104);

            .row {
              display: flex;
              @include marginBottom(27);

              .Col__tezis {
                display: flex;
                @include width(470);

                .Col__point {
                  flex-shrink: 0;
                  @include size(8, 8);
                  @include borderRadius(4);
                  @include marginRight(10);
                  @include marginTop(10);
                  background-color: black;
                  margin-left: 0px;
                }

                .Col__el {
                  @include marginLeft(16);
                }
              }

              .Col_tezis_2 {
                @include marginLeft(88);
              }
            }

            .Stab {
              @include marginTop(145);
            }
          }
        }
      }
    }

    .border_none {
      border: 0;
    }
  }

  .technology__murm{
    @include paddingLeft(278);
    @include paddingBottom(24);
    .murm__title{
      @include marginTop(153);
    }
    .murm__tezis-group{
      display: flex;
      @include marginTop(122);
      .murm__tezis-element{
        @include marginRight(99);
        .tezis__name{
          display: flex;
          justify-content: center;
          font-family: times;
          @include fontSize(35);
          div{
            @include marginRight(7);
          }
          sup{
            @include fontSize(16);
            @include marginTop(6);
          }
        }
        .tezis__surname{
          font-family: "Montserrat-Regular";
          @include fontSize(20);
          text-align: center
        }
      }
    }
    .murm__info{
      @include marginTop(70);
      display: flex;
      .murm__img{
        @include size(966,527);
        border-radius: 15px;
        background-color: rgb(196, 196, 196);
        background-image: url(./assets/plantMob.jpg);
        background-size: cover;
        background-size: 100% auto;
        // background-position: 90% 30%;
      }
      .murm__text-group{
        @include marginLeft(95);
        .murm__text-block{
          @include width(404);
          @include marginBottom(99);
        }
      }
    }
  }

  .plant__title{
    @include paddingLeft(278);
    @include marginTop(140);
  }
  .plant__block{
    @include marginLeft(193);
    @include marginTop(140);
    @include size(1173,402);
    @include borderRadius(15);
    background-color: #FFFFFA;
    box-shadow: 0px 0px 24px #00000029;
    position: relative;
    @include paddingTop(21);
    .plant__icon{
      @include size(26,26);
      @include marginLeft(18);
      svg{
        width: 100%; height: 100%;
      }
    }
    .plant__info{
      @include fontSize(20);
      @include property(line-height,29);
      @include marginTop(42);
      @include marginLeft(85);
      .firstText{
        font-family: "Montserrat-Medium";
      }
      .secondText{
        font-family: "Montserrat-Regular";
      }
      &-chemistry{
        .firstText{
          @include width(667);
          a {
            font-family: "Montserrat-Regular";
            text-decoration: none;
            color: rgb(7, 7, 119);
            :hover{
              color:#F27300;
            }
          }
        }
        .secondText{
          display: flex;
          justify-content: space-between;
          @include width(642);
          @include marginTop(38);
          div{
            @include width(290);
          }
        }
      }
      &-moim{
        .firstText{
          @include width(667);
        }
        .secondText{
          @include marginTop(42);
          @include width(610);
        }
      }
      &-ksor{
        display: flex;
        
        .firstText{
          @include width(289);
          @include marginTop(29);
        }
        .secondText{
          @include marginLeft(65);
          @include width(306);
        }
      }
    }
    .plant__img{
      transition: all 0.7s;
      position: absolute;
      @include top(34);
      @include right(-361);
      transform: translateX(60px);
      @include size(612,334);
      background-color: grey;
      @include borderRadius(15);
      opacity: 0;
    }
    .chemistry{
      background-color: rgb(196, 196, 196);
      background-image: url(./assets/about-analysis.jpg);
      background-size: cover;
      background-size: auto auto;
      background-position: 0% 50%;
    }
    .moim{
      background-color: rgb(196, 196, 196);
      background-image: url(./assets/plant2.jpg);
      background-size: cover;
      // background-size: 105% auto;
      background-position: 20% 35%;
    }
    .ksor{
      background-color: rgb(196, 196, 196);
      background-image: url(./assets/plantReg.jpg);
      background-size: cover;
      // background-size: 105% auto;
      background-position: 0% 0%;
    }
    
  }

  .technology__result {
    @include marginTop(90);
    @include paddingTop(80);
    @include paddingRight(134);
    @include paddingBottom(72);
    background-color: #F8F9FA;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      @include left(400);
      width: 90%;
      height: 100%;
      background-image: url(../../media/img/blot_blue.png);
      background-position: center;
      background-size: 120% 240%;
      background-repeat: none;
      opacity: 0.4;
      z-index: 0;
      // display: none;
    }

    .result__title {
      display: flex;
      @include marginLeft(278);
      z-index: 1;
      position: relative;

      .result__medal {
        @include size(48, 48);
        @include marginRight(54);
        @include marginTop(-5);
      }
    }

    .result__text1 {
      @include marginLeft(278);
      @include marginTop(45);
      z-index: 10;
      position: relative;

    }

    .result__tezis {
      @include height(180);
      position: relative;
      @include marginTop(112);
      @include marginLeft(495);
      @include paddingTop(65);
      @include paddingLeft(60);
      z-index: 1;


      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 2px;
        max-height: 100%;
        border-radius: inherit;
        background: linear-gradient(to bottom, black 0%, #F27300 60%);
      }
    }

    .result__text2 {
      @include marginLeft(278);
      @include marginTop(90);
      z-index: 2;
      position: relative;

    }
  }

}




.hash-ref{
  // transition: all 0.s;
  font-family: "Montserrat-Regular";
  color: rgb(7, 7, 119);
  position: relative;
  // display: flex;
  // .line{
  //   @include marginTop(-2);
  //   @include marginRight(5);
  // }
  // @include marginLeft(22);
  // &::before{
  //   background-color: rgb(7, 7, 119);
  //   @include size(17,2);
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   left: -22px;
  //   z-index: 100;
  // }
  &:hover{
    color: #F27300;
    &::before{
      background-color: #F27300;
    }
  }
}