@import "../../scss/mixin.scss";


.Plants{
  .Warm{
    @include height(1790);
    width: 100%;
    background-color: #FFFFFA;
    @include mobile {
      height: mb(1602);
    };
    .Warm-body{
      width: 100%;
      height: 100%;
      position: relative;
      .orange-blot{
        z-index: 0;
        position: absolute;
        @include top(200);
        @include size(1690,960);
        overflow: hidden;
        opacity: 0.35;
        @include mobile {
          display: none;
          top: mb(173);
          width: mb(738);
          height: mb(716);
        };
        img{
          width: auto;
          height: auto;
          transform: translateY(-20%)
        }
      }
      .About{
        position: relative;
        z-index: 1;
        @include paddingTop(101);
        @include paddingLeft(278);
        @include mobile {
          padding: 0;
        };
        .about-title{
          @include mobile {
            position: relative;
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
            margin-top: mb(45);
          };
        }
        .about-info{
          display: flex;
          align-items: center;
          @include marginTop(42);
          @include mobile {
            position: relative;
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
          };
          .info-text{
            @include paddingTop(45);
            @include mobile {
              padding-top: mb(30);
            };
            p{
              @include width(586);
              @include marginBottom(71);
              @include mobile {
                margin-bottom: 0px;
                width: mb(363);
              };
            }
            p:nth-child(1){
              @include mobile {
                margin-bottom: mb(211);
              };
            }
          }
          .info-img{
            @include marginLeft(98);
            @include size(758,345);
            @include borderRadius(20);
            position: relative;
            overflow: hidden !important;
            @include mobile {
              margin: 0;
              position: absolute;
              top: mb(240); left: mb(0);
              width: mb(375);
              height: mb(132);
              border-radius: mb(20)
            };
            .info-img-blur{
              transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0; left: 0;
              backdrop-filter: blur(8px);
              @include property(border-top-right-radius, 20);
              @include property(border-bottom-right-radius, 20);
              @include mobile {
                display: none;
              };
            }
            img{
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .ServiceOr{
        position: relative;
        z-index: 1;
        @include marginTop(351);
        @include paddingLeft(278);
        @include mobile {
          margin-top: mb(105);
          padding: 0;
        };
        .title{
          @include mobile {
            width: mb(379);
            margin: auto;
            text-align: center;            
          };
        }
        .text-1{
          @include marginTop(50);
          @include width(1104);
          @include mobile {
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
            margin-top: mb(25);
          };
        }
        .tezis-group{
          display: flex;
          @include marginTop(88);
          @include paddingLeft(209);
          @include mobile {
            display: block;
            margin-top: mb(70);
            padding: 0;
            width: mb(415);
            margin: auto;
            padding-left: mb(52);
          };
          .tezis-column{
            @include marginRight(107);
            @include mobile {
              margin: 0;
            };
            &:nth-child(2){
              .tezis:nth-child(2){
                @include width(535);
                @include mobile {
                  width: mb(278);
                };
              }
            }
            .tezis{
              @include marginTop(25);
              @include paddingLeft(28);
              @include width(375);
              position: relative;
              @include mobile {
                margin-top: mb(25);
                width: mb(278);
                padding-left: mb(25);
              };
              &::after{
                content: '';
                position: absolute;
                @include top(10);
                @include left(0);
                @include size(8,8);
                @include borderRadius(4);
                background-color: black;
              }
            }
          }
        }
        .text-2{
          @include marginTop(118);
          @include width(830);
          @include mobile {
            width: mb(379);
            margin: auto;
            padding-left: mb(10);
            margin-top: mb(65);
          };
        }
      }
      .Catalog{
        @include paddingLeft(278);
        @include marginTop(150);
        font-family: "Montserrat-Regular";
        @include mobile {
          padding: 0;
          margin-top: mb(85);
        };
       .row{
        display: flex;
        @include marginBottom(217);
        @include mobile {
          display: block;
          margin-bottom: mb(136);
          .plant:nth-child(1){
            margin-bottom: mb(136);
          }
        };
        .plant{
          @include marginRight(264);
          @include mobile {
            margin: auto;
            width: mb(363);
          };
          a{
          &:hover{
            .plant-title{
              color: orange;
              @include mobile {
                color: black;
              };
            }
          }
          cursor: pointer;
          @include marginRight(264);
          .plant-img{
            @include size(564,291);
            overflow: hidden;
            @include borderRadius(20);
            background-color: rgb(196, 196, 196);
            // background-color: rgba(128, 128, 128, 0.329);
            @include mobile {
              width: mb(363);
              height: mb(182);
              border-radius: mb(20)
            };
            img{
              transition: all 0.3s;
              width: 100%;
              height: auto;
              &:hover{
                transform: scale(1.03);
                @include mobile {
                  transform: scale(1);
                };
              }
            }
          }
          .plant-title{
            transition: all 0.2s;
            @include fontSize(26);
            @include marginTop(35);
            @include mobile {
              font-size: mb(18);
              margin-top: mb(35);
              width: 100%;
              text-align: center;
            };
          }
          .plant-info{
            @include fontSize(18);
            @include width(564);
            @include marginTop(20);
            @include mobile {
              width: mb(363);
              font-size: mb(15);
              margin-top: mb(25);
              line-height: mb(30);
            };
          }
          }
        }
       }
      }

    }
  }
  .Cold{
    @include height(2026);
    @include mobile {
      height: mb(1974+147);
      padding-bottom: mb(147);
    };
    width: 100%;
    background-color: #F8F9FA;
  }
}