@import "../../scss/mixin.scss";

.archive{
  @include paddingBottom(100);
  @include paddingTop(40);
  background-color: #FFFFFA;
  @include mobile {
    padding-top: mb(20);
    padding-bottom: mb(20);
  };
  .archive__page{
    font-family: "Montserrat-Regular";
    @include fontSize(15);
    @include marginLeft(200);
    user-select: none;
    @include mobile {
      margin-left: mb(26);
      font-size: mb(15);
    };
  }
  .archive__section{
    @include paddingTop(45);
    @include marginBottom(75);
    @include mobile {
      padding-top: mb(20); 
      margin: 0;
      margin-bottom: mb(80);

    };
    .section__title{
        font-family: "SourceSerif4-Bold";
        @include marginLeft(378);
        @include fontSize(25);
        position: relative;
        display: inline-block;
        width: auto;
        @include mobile {
          padding: 0; margin: 0;
          font-size: mb(17);
          margin-left: mb(26);
        };
        &::after {
            transition: all 0.2s;
            content: '';
            position: absolute;
            @include bottom(-10);
            left: 0;
            width: 69%;
            height: 2px;
            background: linear-gradient(to right, black 0%, #F27300 100%);
            animation: gradient 3s 5;
            @include mobile {
              width: mb(262);
              bottom: mb(-10);
              height: 1px;
            };
            // background: linear-gradient(to right, black 0%, #F27300 50%, black 100%);
        }
    }
    .section__docs{
      @include marginLeft(378);
      @include marginTop(85);
      @include paddingLeft(200);
      @include mobile {
        padding: 0; margin: 0;
        padding-left: mb(37);
      };
      .section__document{
        user-select: none;
        font-family: "Montserrat-Regular";
        @include fontSize(20);
        @include marginTop(30);
        @include mobile {
          &:nth-child(1){
            margin-top: mb(41);
          }
          font-size: mb(15);
          margin-top: mb(52);
        };
        a{
          transition: all 0.2s;
          display: flex;
          flex-shrink: none;
          align-items: center;
          @include width(850);
          &:hover{
            @include mobile {
              color: black;
            };
            color: #F27300;
          }
          @include mobile {
            width: mb(342);
          };
        }
        p{
          width: 380px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        svg{
          @include size(25, 40);
          @include marginRight(30);
          @include marginTop(-5);
          flex-shrink: 0;
          @include mobile {
            width: mb(25);
            height: mb(28);
            margin: 0;
            margin-right: mb(15)
          };
        }
      }
    }
  }
}


// @keyframes gradient {
//   0%{
//     background: linear-gradient(to right, black 0%, #F27300 100%);
//   }
//   50%{
//     background: linear-gradient(to right, black 0%, #F27300 50%, black 100%);
//   }
//   100%{
//     background: linear-gradient(to right, #F27300 0%, black 100%);
//   }
// }