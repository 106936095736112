/* Указываем box sizing */

@import "mixin.scss";

.App{
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  // max-height: 100vh;
  // scroll-behavior: smooth;
  /* text-rendering: optimizeSpeed; */
  line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

::-webkit-scrollbar {
  width: 7px;
  // background-color: black;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  height: 20%;
  border-radius: 10px;
  // background-color: #f27500b2;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 5px solid white;
  
}


::-webkit-scrollbar * {
  background:transparent;
}
::-webkit-scrollbar-thumb {
  background:#999 !important;
}

.QA-App{
  @include mobile {
    display: none;
  };
}

.crumbs {
  font-family: Montserrat-Regular;
  font-size: 15px;
  position: absolute;
  top:32px;
  left: 140px;
  display: flex;
 
}
.crumbs__arrow{
  margin-top: -1px;
  transition: all 1s;
  transform: rotate(0deg);
}

.crumbs:hover >.crumbs__arrow {
  transition: all 1s;
  transform: rotate(-180deg);
}


.App{
  // height: 100vh;
  // position: relative;
}

@media(max-width:768px){
  .crumbs {

      font-size: 10px;
      position: absolute;
      top: 18px;
      left: 20px;
      display: flex;
      display: none;
     
  }
  .crumbs__main{
      display: none;
  }
  .crumbs__arrow{
      margin-top: 0px;
      transition: none;
      transform: rotate(180deg);
      width: 14px;
      height: auto;
  }v
  
  .crumbs:hover >.crumbs__arrow {
      transition: none;
  }
}



/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */



video{
  /* outline: none; */
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}


h1{
  font-family: SourceSerif4-Bold;
  margin: 0;
  @include fontSize(35);
  @include mobile {
    font-family: SourceSerif4-Bold-Original !important;
  };
}

h2{
  font-family: Montserrat-Bold;
  margin: 0;
  @include fontSize(25);
  @include mobile {
    font-family: SourceSerif4-Bold-Original !important;
  };
}

h3{
  font-family: SourceSerif4-Bold;
  margin: 0;
  @include fontSize(35);
  @include mobile {
    font-family: SourceSerif4-Bold-Original !important;
  };
}

p{
  font-family: Montserrat-Regular;
  margin: 0;
  @include fontSize(20);
  @include property(line-height,29);
}
a{
  text-decoration: none;
  color: black;
}

body{
  margin: 0;
  padding: 0;
  @include paddingTop(100);
  background-color: #FFFFFA;
  overflow-x: hidden;
  @include mobile {
    padding-top: 0;
  };
}

.bd{
  height: 100vh - adpt(100)
}

img {
  /* image-rendering: optimizeQuality; */
  image-rendering: -webkit-optimize-contrast;
  /* -webkit-backface-visibility: hidden;  */
  /* -ms-transform: translateZ(0); 
  -webkit-transform: translateZ(0); 
  transform: translateZ(0); */
}

.App{
  position: relative;
  height: 100vh;
}

.body{
  overflow: hidden !important;
}

body{
  background-color: #FFFFFA;
}

html{
  background-color: #FFFFFA;
}
.App{
  background-color: #FFFFFA;
}

$v: 0.7;

.push{
  transition: opacity 0.3s;
  display: none;
  opacity: 0;
  @include size(1039*$v,172*$v);
  font-family: 'Montserrat-Bold';
  @include fontSize(30*$v);
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%; transform: translate3d(-50%, -50%, 0);
  z-index: 103;
  background-color: #FFFFF5;
  box-shadow: 0px 0px 24px #00000029;
  @include borderRadius(15*$v);
  @include paddingTop(65*$v);
  .push-wrap{
    position: relative;
    width: 100%;
    &::before{
      content: '';
      position: absolute;
      @include top(-12);
      left: 50%;
      transform: translateX(-50%);
      @include width(766*$v);
      height: 2px;
      background: linear-gradient(to right, #F27300 30%, black 100%)
    }
    &::after{
      content: '';
      position: absolute;
      @include bottom(-12);
      left: 50%;
      transform: translateX(-50%);
      @include width(766*$v);
      height: 2px;
      background: linear-gradient(to right, #F27300 30%, black 100%)
    }
  }
}

svg{
  image-rendering: -webkit-optimize-contrast;
  /* text-rendering:auto;
  shape-rendering: geometricPrecision; */
  /* -webkit-text-stroke: 1px */
  /* stroke-linejoin: arcs; */

}

.content__container{
  // /* background-color: red; */
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: auto;
  // background-color: red;
}

.attantion{
  display: none;
}

span{
  margin: 0;
}

img{
  width: 100%;
  height: 100%;
}

// html {
//   -webkit-user-select: none !important; /* Safari */
//    -ms-user-select: none !important; /* Internet Explorer/Edge */
//        user-select: none !important; /* Нестандартный синтаксис, поддерживаемый как в Firefox, так и в Chrome */
// }


@media(max-width: 768px){
    h1{
      font-size: mb(19);
    }
    
    h2{
      font-size: mb(17);
      line-height: mb(25px)
    }

    h3{
      font-size: mb(19);
    }

    
    p{
      font-size: mb(15);
      line-height: mb(30)
    }
    .wow{
      animation-name: none !important;
      visibility: visible !important;
    }
}

// @media(min-width: 769px) and (max-width: 1022px) {
//   .attantion{
//     font-family: Montserrat-Bold;
//     font-size: 20px;
//     display: block;
//     width: 600px;
//     margin: auto;
//     margin-top: 150px;
//     text-align: center;
//     opacity: 0.4;
//   }
//   h1{
//     font-size: 22px;
//   }
  
//   h2{
//     font-size: 18px;
//   }
  
//   p{
//     font-size: 16px;
//     line-height: 24px;
//   }

// }

// @media(min-width: 1023px) and (max-width: 1439px) {
//   h1{
//     font-size: 25px;
//   }
  
//   h2{
//     font-size: 20px;
//   }
  
//   p{
//     font-size: 16px;
//     line-height: 24px;
//   }

// }

// @media(min-width: 1440px) and (max-width: 1880px) {
//   h1{
//     font-size: 25px;
//   }
  
//   h2{
//     font-size: 20px;
//   }
  
//   p{
//     font-size: 16px;
//     line-height: 24px;
//   }

// }


::-moz-selection {
  color: rgb(0, 0, 0);
  background: rgb(233, 232, 232);
}

::selection {
  color: rgb(0, 0, 0);
  background: rgb(233, 232, 232);
}


input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}


textarea::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
textarea::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
textarea:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
textarea:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
textarea:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
