@import "../../scss/mixin.scss";

.Plant {
  .warm-background {
    @include height(1316+101);
    background-color: #FFFFFA;
    @include mobile {
      height: mb(1390);
    };
    .block-init {
      @include paddingTop(101);
      @include paddingLeft(278);
      @include mobile {
        padding: 0;
        position: relative;
      };

      .init-title {
        @include mobile {
          width: mb(379);
          margin: auto;
          margin-top: mb(45);
          padding-left: mb(10);
        };
      }

      .init-info {
        display: flex;
        align-items: center;
        @include mobile {
          flex-flow: column;
        };

        .init-text {
          @include marginRight(97);
          @include mobile {
            margin: 0;
            order: 2
          };

          p {
            @include width(638);
            @include marginTop(87);
            @include mobile {
              margin: 0;
              width: mb(363);
              margin: auto;
              margin-top: mb(30);
            };
          }

          .init-points {
            @include marginTop(58);
            @include mobile {
              margin: 0;
              position: absolute; 
              top: mb(151); left: 50%;
              transform: translateX(-50%);
              z-index: 100;
            };

            .init-point {
              @include marginBottom(25);
              display: flex;
              font-family: "Montserrat-Regular";
              @include fontSize(20);
              @include mobile {
                align-items: center;
                margin-bottom: mb(32);
                font-size: mb(15);
                color: white
              };

              .point-num {
                @include width(28);
                color: #F7931E;
                @include marginRight(26);
                @include mobile {
                  width: mb(30);
                  margin-right: mb(22);
                  font-size: mb(20);
                };
              }
            }
          }
        }

        .init-img {
          @include size(673, 347);
          @include borderRadius(15);
          overflow: hidden;
          background-color: rgb(221, 221, 221);
          @include mobile {
            order: 1;
            width: mb(363);
            height: mb(446);
            overflow: hidden;
            border-radius: mb(15);
            position: relative;
            z-index: 0;
            margin: auto;
            margin-top: mb(25);
          };

          &::after{
            @include mobile {
              content: '';
              position: absolute;
              top: 0; left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
            };
          }

          img {
            width: 100%;
            height: auto;
            @include mobile {
              max-width: 500%;
              width: auto;
              height: 100%;
              transform: translateX(mb(-250))
            };
          }
        }
      }
    }
    .block-property{
      @include marginTop(314);
      @include paddingLeft(234);
      @include mobile {
        margin-top: mb(130);
        padding: 0;
      };
      .property-title{
        @include paddingLeft(44);
        @include mobile {
          padding: 0;
          width: mb(379);
          margin: auto;
          margin-top: mb(45);
          padding-left: mb(10);
        };
      }
      .properties{
        display: flex;
        justify-content: space-between;
        @include width(1452);
        @include marginTop(87);
        @include mobile {
          margin: auto;
          margin-top: mb(78);
          display: block;
          width: mb(353);
        };
        .property{
          @include width(410);
          @include height(70);
          font-family: 'Montserrat-Regular';
          @include fontSize(20);
          text-align: center;
          position: relative;
          @include property(line-height,24);
          @include mobile {
            width: 100%;
            height: auto;
            font-size: mb(15);
            line-height: mb(25);
            margin-bottom: mb(119);
          };
          &::after{
            content: '';
            position: absolute;
            @include width(360);
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            background: linear-gradient(to right, black 0%, #F27300 60%);
            @include mobile {
              width: mb(223);
              bottom: mb(-15);
            };
          }
        }
      }
    }

  }

  .cold-background {
    background-color: #F8F9FA;
    // @include height(2500);
    @include paddingBottom(50);
    @include mobile {
      padding: 0;
    };
    .block-character{
      @include paddingTop(103);
      @include paddingLeft(140);
      @include mobile {
        width: 100%;
        padding: 0;
        padding-top: mb(10);
      };
      .character-title{
        @include paddingLeft(138);
        @include mobile {
          padding: 0;
          width: mb(379);
          margin: auto;
          margin-top: mb(45);
          padding-left: mb(10);
        };
      }
      .Table-wrap{
        @include mobile {
          width: 100%;
          overflow: auto;
          position: relative;
          &::-webkit-scrollbar{
            display: none;
          }
        };        
      .Table{
        @include marginBottom(215);
        @include marginTop(105);
        @include width(1640);
        font-family: 'Montserrat-Regular';
        height: auto;
        transition: height 0.3s;
        @include mobile {
          width: mb(706+100);
          margin-top: mb(48);
          margin-bottom: mb(80);
          padding-left: mb(22);
        };
        .Table-name{
          @include fontSize(20);
          width: 100%;
          @include height(54);
          position: relative;
          @include mobile {
            font-family: 'Montserrat-Italic';
            font-size: mb(13);
            height: mb(38);
          };
          &::after{
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: #707070;
          }
        }
        .Table-columns{
          display: flex;
          justify-content: space-between;
          width: 100%;
          @include marginTop(14);
          @include marginBottom(20);
          @include mobile {
            margin-top: mb(10);
            margin-bottom: mb(15);
          };
          .column{
            @include fontSize(22);
            @include height(38);
            position: relative;
            @include mobile {
              font-size: mb(13);
              height: mb(30);
            };
            &::before{
              content: '';
              position: absolute;
              bottom: 0; left: 0;
              width: 100%;
              height: 1px;
              background-color: #707070;
            }
            &:nth-child(1){
              @include width(131);
              @include mobile {
                width: mb(43);
              };
            }
            &:nth-child(2){
              @include width(603);
              @include mobile {
                width: mb(229);
              };
            }
            &:nth-child(3){
              @include width(285);
              @include mobile {
                width: mb(58+100);
              };
            }
            &:nth-child(4){
              @include width(517);
              @include mobile {
                width: mb(320);
              };
            }
          }
        }
        .Table-rows{
          @include fontSize(22);
          @include mobile {
            font-size: mb(14);
          };
          .row{
            display: flex;
            justify-content: space-between;
            @include marginBottom(48);
            @include mobile {
              margin-bottom: mb(40);
            };
            .value{
              &:nth-child(1){
                @include width(131);
                @include mobile {
                  width: mb(43);
                };
              }
              &:nth-child(2){
                @include width(603);
                @include mobile {
                  width: mb(229);
                };
              }
              &:nth-child(3){
                @include width(285);
                @include mobile {
                  width: mb(58+100);
                };
              }
              &:nth-child(4){
                @include width(517);
                @include mobile {
                  width: mb(320);
                };
              }
            }
          }
        }
        .sub{
          display: none;
          opacity: 0;
          transition: opacity 0.3s;
        }
        .button{
          @include width(36);
          cursor: pointer;
          height: auto;
          margin: auto;
          @include mobile {
            width: 100vw; height: auto;
            position: sticky;
            left: 0;
            bottom: 0;
            margin-left: mb(-26);
          };
          .circle{
            transition: all 0.3s;
            cursor: pointer;
            margin: auto;
            @include size(8,8);
            position: relative;
            background-color: #9C9C9C;
            @include borderRadius(4);
            &:hover{
              background-color: orange;
            }
            &::before{
              content: '';
              position: absolute;
              top: 0;
              @include left(-6-8);
              @include size(8,8);
              background-color: inherit;
              @include borderRadius(4);
            }
            &::after{
              content: '';
              position: absolute;
              top: 0;
              @include left(6+8);
              @include size(8,8);
              background-color: inherit;
              @include borderRadius(4);
            }
          }
        }
      }
      }
      .Murm-attention{
        font-family: "Montserrat-Regular";
        @include fontSize(32);
        @include width(1200);
        color: grey;
        text-align: center;
        margin: auto;
        @include marginTop(150);
        @include marginBottom(150);
        @include mobile {
          margin: 0;
          margin-top: mb(150);
          padding-bottom: mb(150);
          width: auto;
          font-size: mb(15)
        };
      }
      
    }
    .request{
      @include marginTop(147);
      font-family: 'Montserrat-Regular';
      @include fontSize(20);
      @include marginBottom(221);
      @include mobile {
          display: none
          
      };
      .request-info{
        @include width(1050);
        text-align: center;
        margin: auto;
      }
      .request-button{
        transition: all 0.5s;
        margin: auto;
        @include marginTop(33);
        @include size(612,61);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #002266;
        @include borderRadius(10);
        color: white;
        cursor: pointer;
        &:hover {
          background-color: rgb(36, 104, 146);
          @include mobile {
            background-color: #002266
          };
        }
      }
    }
  }
}