@font-face {
    font-family: 'Montserrat-Bold';
    src:url('../media/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    }

@font-face {
    font-family: 'Montserrat-Regular';
    src:url('../media/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}


@font-face {
    font-family: 'Montserrat-Medium';
    src:url('../media/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}

@font-face {
    font-family: 'Montserrat-Italic';
    src:url('../media/fonts/Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}

@font-face {
    font-family: 'Montserrat-Light';
    src:url('../media/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src:url('../media/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}

@font-face {
    font-family: 'SourceSerif4-Bold';
    src:url('../media/fonts/SourceSerif4Caption-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}

@font-face {
    font-family: 'SourceSerif4-Semibold';
    src:url('../media/fonts/SourceSerif4Caption-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}

@font-face {
    font-family: 'SourceSerif4-Bold-Original';
    src:url('../media/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;  
}