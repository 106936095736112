@import "../../../scss/mixin.scss";
@import "../../../scss/fonts.scss";

.menu-open{
  display: none;
 
  @include mobile {
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    display: none;
    position: fixed;
    top: 0; left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0);
  };
.menu-wrapper{
  @include mobile {
    width: 100%;
    height: 100%;
    position: relative;
  };
  .menu-body{
    @include mobile {
      transform: translateY(100%);
      transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
      position: absolute;
      bottom: 0;
      left: 0;
      padding-top: mb(25);
      width: 100vw;
      border-top-left-radius: mb(30);
      border-top-right-radius: mb(30);
      background-color: #FFFFFA
    };
    .close{
      @include mobile {
        width: mb(63);
        height: mb(6);
        border-radius: mb(10);
        margin: auto;
        background-color: #6D6D6D;
      };
    }
    .close-form{
      @include mobile {
        background-color: #FF5E8A;
      };
    }
    .links{
      .link{
        @include mobile {
          font-family: 'Montserrat-Regular';
          font-size: mb(14);
          text-align: center;
          margin: auto;
          width: mb(223);
          height: mb(57);
          display: flex;
          align-items: center;
          border-top: 1px solid #D4D4D4;
          &:nth-child(1){
            border: 0px ;
            margin-top: mb(34)
          }
          a{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        };
      }
    }
    .contacts{
      display: flex;
      align-items: center;
      margin-top: mb(19);
      width: auto;
      justify-content: center;
      margin-bottom: mb(97);
      .phone{
        @include mobile {
          font-family: 'Montserrat-Regular';
          font-size: mb(11);
          margin-right: mb(47);
        };
      }
      .email{
        @include mobile {
          font-family: 'Montserrat-Regular';
          font-size: mb(12);
        };
      }
    }

    .content{
      height:mb(638);
      max-height: 90vh;
      overflow: scroll;
    }


    .form-mobile{
      display: block;
      margin-top: mb(49);
      padding-left: mb(50);
      .field{
        font-family: 'Montserrat-Regular';
        margin-bottom: mb(25);
        .name{
          font-size: mb(16);
          color: #002166;
        }
        input{
          margin-top: mb(10);
          border: none;
          outline: none;
          font-size: mb(15);
        }
        textarea {
          margin-top: mb(10);
          transition: all 0.2s;
          resize: none;
          width: mb(320);
          height: mb(134);
          outline: none;
          border: none;
          overflow: scroll;
          font-size: mb(15);
          &::-webkit-scrollbar {
            width: 2px;
          }
        }
      }
      .button{
        font-family: "Montserrat-Regular";
        margin-top: mb(40);
        width: mb(132);
        display: flex;
        align-items: center;
        margin-left: mb(92);
        margin-bottom: mb(41);

        .figure{
          flex-shrink: 0;
          width: mb(45);
          height: mb(6);
          border-radius: mb(10);
          background-color: #002266;
          margin-right: mb(10);
        }
        div{
          flex-shrink: 0;
          font-size: mb(15);
        }
      }
    }

    .calculating{
      padding-left: mb(43);
      margin-top: mb(35);
      .radio-groups{
        .radio-group{
          .radio-title{
            font-family: 'Montserrat-Bold';
            font-size: mb(16)
          }
          .wrap{
            display: block;
            margin-top: mb(20);
            .radio{
              margin-bottom: mb(20);
              display: flex;
              align-items: center;
              font-family: 'Montserrat-Regular';
              font-size: mb(15);
              .radio-button{
                cursor: pointer;
                width: mb(19);
                height: mb(19);
                border-radius: mb(10);
                border: 2px solid #B1B1B1;
                margin-right: mb(15);
              }
              .active{
                position: relative;
                &::after{
                  content: '';
                  width: mb(11); height: mb(11);
                  border-radius: mb(6);
                  position: absolute;
                  top:50%; left: 50%;
                  transform: translate3d(-50%,-50%,0);
                  background-color: #6C6CD5;
                }
              }
            }
          }
          .flex{
            display: flex;
            .radio{
              &:nth-child(2){
                margin-left: mb(45);
              }
            }
          }
        }
      }
      .slider-value{
        margin-top:mb(40);
        .slider-title{
          font-family: 'Montserrat-Bold';
          font-size: mb(16);
        }
        .slider-body{
          width: mb(316);
          height: mb(20);
          margin-top: mb(60);
        }
      }
      .button{
        font-family: "Montserrat-Regular";
        margin-top: mb(40);
        width: mb(132);
        display: flex;
        align-items: center;
        margin-left: mb(92);
        margin-bottom: mb(41);

        .figure{
          flex-shrink: 0;
          width: mb(45);
          height: mb(6);
          border-radius: mb(10);
          background-color: #002266;
          margin-right: mb(10);
        }
        div{
          flex-shrink: 0;
          font-size: mb(15);
        }
      }
    }


    .close-opener-body{
      width: 100vw;
      height: mb(567);
    }
  }
}
}