@import "../../../../scss/mixin.scss";

.repair-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include marginBottom(180);
    @include height(600);
}
  
.repair-content {
    display: flex;
    flex-direction: row;
    width: 100%;
}
  
.repair-text {
    flex: 1;
    @include paddingTop(10);
    @include paddingRight(40);
    @include paddingBottom(30);
    @include paddingLeft(20);
    @include height(300);
}
  
.repair-text h2 {
    @include fontSize(24);
}
  
.repair-description {
    @include marginTop(18);
    @include marginBottom(20);
    @include fontSize(18);
}
  
.repair-list {
    list-style: none;
    padding: 0;
    overflow: visible;
}
  
.repair-list li {
    @include marginBottom(10);
    font-family: Montserrat-Regular;
    @include fontSize(18);
}
  
.repair-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include paddingLeft(20);
    @include marginTop(30);
    overflow: hidden;
    @include borderRadius(15);
}
  
.repair-image img {
    height: 100%;
    width: auto; 
    min-width: 120%; 
    object-fit: cover;
    @include borderRadius(15);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1); 
    @include marginLeft(-10);
}

.show-more-btn {
    background: none;
    border: none;
    color: #007bff;
    @include fontSize(16);
    cursor: pointer;
    @include marginTop(10);
    display: flex;
    align-items: center;
    transition: color 0.3s;
    font-family: Montserrat-Regular;
    @include marginTop(20);
}
  
.show-more-btn:hover {
    color: #0056b3;
}
